import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Text from '../components/text';
import Header from '../components/header';
// import RarityList from '../components/rarityList';

import untConstruction from '../images/how-are-unts-constructed.png';

import rarity1 from '../images/rarity/rarity_chart_1.png';
import rarity2 from '../images/rarity/rarity_chart_2.png';
import rarity3 from '../images/rarity/rarity_chart_3.png';
import rarity4 from '../images/rarity/rarity_chart_4.png';
import rarity5 from '../images/rarity/rarity_chart_5.png';
import rarity6 from '../images/rarity/rarity_chart_6.png';
import rarity7 from '../images/rarity/rarity_chart_7.png';
import rarity8 from '../images/rarity/rarity_chart_8.png';
import rarity9 from '../images/rarity/rarity_chart_9.png';
import rarity10 from '../images/rarity/rarity_chart_10.png';

import './rarity.scss';

const RarityPage = () => (
  <Layout>
    <Seo title="Cheeky Unts - Rarity" />
    <Header />
    <div className="rarity">
      <Text>
        <h1>Rarity</h1>
        <p>
          All 4&#44;444 Unts are animated and programmatically generated from 10 possible
          layers making for squillions of possible combinations.
          We did have an actual number but we&apos;ve misplaced our FX-82.
        </p>
        <img src={untConstruction} alt="How Unts are Made" width="768px" className="rarity__image" />
        <p>
          The most pure Unts will just have a background and
          character(Unt), commonly referred to as a
          {' '}
          <span style={{ color: '#e27f19' }}>Butt Naked Unt</span>
          .
        </p>
        <p>
          {' '}
          Unts with 5 or more items are
          {' '}
          <span style={{ color: '#e27f19' }}>Poser Unts</span>
          .
          {' '}
          <em>Specials</em>
          {' '}
          do not count towards the total for Posers.
        </p>
      </Text>
      <div className="separator" />
      <div className="rarity__masonry">
        <Text wide>
          <div className="masonry-with-columns ">
            <div>
              <img src={rarity8} alt="Rarity Chart Sneek Peak" className="rarity__image" />
            </div>
            <div>
              <img src={rarity2} alt="Rarity Chart Sneek Peak" className="rarity__image" />
            </div>
            <div>
              <img src={rarity3} alt="Rarity Chart Sneek Peak" className="rarity__image" />
            </div>
            <div>
              <img src={rarity6} alt="Rarity Chart Sneek Peak" className="rarity__image" />
            </div>
            <div>
              <img src={rarity1} alt="Rarity Chart Sneek Peak" className="rarity__image" />
            </div>
            <div>
              <img src={rarity5} alt="Rarity Chart Sneek Peak" className="rarity__image" />
            </div>
            <div>
              <img src={rarity4} alt="Rarity Chart Sneek Peak" className="rarity__image" />
            </div>
            <div>
              <img src={rarity7} alt="Rarity Chart Sneek Peak" className="rarity__image" />
            </div>
            <div>
              <img src={rarity9} alt="Rarity Chart Sneek Peak" className="rarity__image" />
            </div>
            <div>
              <img src={rarity10} alt="Rarity Chart Sneek Peak" className="rarity__image" />
            </div>
          </div>
          *Percentages are provisional until all Unts are minted.
        </Text>
      </div>
    </div>

  </Layout>
);

export default RarityPage;

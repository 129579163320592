import * as React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames/bind';

import './text.scss';

const Text = ({ children, wide }) => (
  <section className="text">
    <div
      className={classNames('text__content', {
        '-wide': wide,
      })}
    >
      {children}
    </div>
  </section>
);

Text.defaultProps = {
  wide: false,
};

Text.propTypes = {
  wide: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Text;
